import useGlobal from 'hooks/useGlobal'
const { CircularProgress } = require('@mui/material')
const { StyledBackdrop } = require('theme/backdrop')

function Loading({ pageLoading }) {
  const { openLoading } = useGlobal()
  return (
    <StyledBackdrop open={pageLoading ? pageLoading : openLoading}>
      <CircularProgress color='inherit' />
    </StyledBackdrop>
  )
}

export default Loading
