import { createContext } from 'react'
import useRequestsProvider from '../hooks/useRequestsProvider'

const RequestsContext = createContext()

export function RequestsProvider(props) {
  const requestsProvider = useRequestsProvider()
  return (
    <RequestsContext.Provider value={requestsProvider}>
      {props.children}
    </RequestsContext.Provider>
  )
}

export default RequestsContext
