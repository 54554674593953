import api from './api'

export async function login(body) {
  const { data } = await api.post('/login', { ...body })
  return data
}

export function getHeaders(token) {
  const Authorization = { headers: { authorization: `Bearer ${token}` } }
  return Authorization
}

export async function detailUser(token) {
  const { data } = await api.get('/user', { ...getHeaders(token) })
  return data
}
