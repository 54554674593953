import styled from '@emotion/styled'
import { Backdrop } from '@mui/material'

export const StyledBackdrop = styled(Backdrop)`
  color: white;
  height: 100vh;
  width: 100vw;
  z-index: 5;
`

export const StyledBackdropModal = styled(Backdrop)`
  background: rgba(52, 64, 84, 0.7);
  backdrop-filter: blur(8px);
  z-index: 2;
  overflow-y: auto;
`
