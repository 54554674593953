import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyles from 'theme/globalStyles'
import Loading from './components/Loading'
import { GlobalProvider } from './contexts/GlobalContext'
import { RequestsProvider } from './contexts/RequestsContext'
import { theme } from './theme/index'

const MainRoutes = React.lazy(() => import('./routes'))

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <CssBaseline />
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <GlobalProvider>
          <RequestsProvider>
            <Suspense fallback={<Loading pageLoading />}>
              <MainRoutes />
            </Suspense>
          </RequestsProvider>
        </GlobalProvider>
      </ThemeProvider>
    </React.StrictMode>
  </BrowserRouter>
)
